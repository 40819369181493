import { observer } from 'mobx-react-lite';
import { TFunction, useTranslation } from 'react-i18next';
import FormInputText from '@/components/common/form/FormInputText';
import FormInputDate from '@/components/common/form/FormInputDate';
import dayjs from '@/lib/dayjs';
import { Select } from 'antd';
import FormInputTextarea from '@/components/common/form/FormInputTextarea';
import FormInputTime from '@/components/common/form/FormInputTime';
import { FormLabel } from '@/components/ui/form';
import FormMultipleSelect from '@/components/common/form/FormMultipleSelect';
import { useFormContext } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { GetListUsers } from '@/modules/services/user.service';
import { APP_PERMISSION } from '@/constants/permissionConfig';
import { cn } from '@/lib/utils';
import React, { useRef } from 'react';
import FormInputCheckboxGroup from '@/components/common/form/FormCheckboxGroup';
import { User } from '@/interfaces/account';
import { APIResponse } from '@/interfaces/common/response';
import _ from 'lodash';
import FormInputCheckbox from '@/components/common/form/FormInputCheckbox';
import { Item, renderText } from '@/components/common/pages/Element';
import { useUpdateEffect } from 'react-use';
import TooltipComponent from '@/components/common/pages/ToolTip';
import { CheckedState } from '@radix-ui/react-checkbox';
import { SpinIcon } from '@/components/common/svg';

const { Option, OptGroup } = Select;

type Props = {
    type?: 'created' | 'edit' | 'detail';
} & Record<string, any>;

export type SelectedOption = { label: string; value: string; checked?: boolean };
export type Section = {
    label: string;
    options: SelectedOption[];
};

type CustomLabelProps = {
    optGroupLabel: string;
    t: TFunction<'translation', undefined>;
    defaultChecked?: boolean;
    onChange: (optGroupLabel: string, checked: boolean) => void;
    labelSelect?: string;
    isEmpty?: boolean;
};

const CustomLabel = ({
    optGroupLabel,
    t,
    onChange,
    isEmpty,
    defaultChecked = false,
    labelSelect = 'Select All',
}: CustomLabelProps) => {
    const [checked, setChecked] = React.useState(defaultChecked);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(e.target.checked);
        onChange(optGroupLabel, e.target.checked);
    };
    useUpdateEffect(() => {
        setChecked(defaultChecked);
    }, [defaultChecked]);
    return (
        <>
            <div className={'flex item-center p-2 text-ip'}>
                <div> {optGroupLabel} </div>
                {!isEmpty && (
                    <div className={'ml-4'}>
                        <label className={'flex'}>
                            <input
                                key={optGroupLabel}
                                id={optGroupLabel}
                                className={'w-5'}
                                type="checkbox"
                                checked={checked}
                                onChange={handleChange}
                            />
                            <label htmlFor={optGroupLabel} className={'ml-2'}>
                                {labelSelect}
                            </label>
                        </label>
                    </div>
                )}
            </div>
            {isEmpty && <div className="text-ip text-center text-foreground">{t('no_data')}</div>}
        </>
    );
};

const MeetingForm = ({ type = 'created' }: Props) => {
    const { t } = useTranslation();
    const { setValue, getValues, clearErrors } = useFormContext();
    dayjs().utcOffset();
    const selectUserContainer = React.useRef<HTMLDivElement | null>(null);
    const [sectionOptions, setSectionOptions] = React.useState<Section[]>([]);
    const [options, setOptions] = React.useState<Section[]>([]);
    const container = useRef<HTMLDivElement>();
    const [isLoadUser, setIsLoadUser] = React.useState(false);

    const option = useQuery({
        queryKey: ['getUserList'],
        queryFn: () => getUserList(),
        initialData: [],
    });

    const handleResetUsetId = (ids: string[] = []) => {
        const usersId = getValues('user_ids') || [];
        if (usersId.length === 0) {
            return;
        }
        const newUserId = usersId.filter((id: string) => ids.includes(id));
        setValue('user_ids', newUserId);
    };

    const getUserList = async () => {
        try {
            setIsLoadUser(true);
            const { data: mayors } = await GetListUsers<APIResponse<User[]>>({
                role: APP_PERMISSION.MAYOR,
                order_by: 'name asc updated_at desc',
                per_page: 1000,
            });
            const { data: representatives } = await GetListUsers<APIResponse<User[]>>({
                role: APP_PERMISSION.REPRESENTATIVE,
                order_by: 'name asc updated_at desc',
                per_page: 1000,
            });
            const user_ids: string[] = getValues('user_ids') || [];
            const mayorLabel = t(`meeting.form_meeting.${APP_PERMISSION.MAYOR}`);
            const representativeLabel = t(`meeting.form_meeting.${APP_PERMISSION.REPRESENTATIVE}`);

            const optionMayors = mayors.map((mayor) => {
                return {
                    value: mayor.id,
                    label: mayor.name,
                    checked: user_ids.includes(mayor.id),
                };
            });
            const optionRepresentatives = representatives.map((representative) => {
                return {
                    value: representative.id,
                    label: representative.name,
                    checked: user_ids.includes(representative.id),
                };
            });
            const mayorIds = optionMayors.map((mayor) => mayor.value);
            const representativeIds = optionRepresentatives.map((representative) => representative.value);
            const isContainMayorIds = _.difference(mayorIds, user_ids).length === 0;
            const isContainRepresentativeIds = _.difference(representativeIds, user_ids).length === 0;
            const optionMultipleSelect = [
                {
                    label: mayorLabel,
                    options: optionMayors,
                },
                {
                    label: representativeLabel,
                    options: optionRepresentatives,
                },
            ];

            handleResetUsetId([...mayorIds, ...representativeIds]);

            setValue(`selectAll_${mayorLabel}`, isContainMayorIds);
            setValue(`selectAll_${representativeLabel}`, isContainRepresentativeIds);
            setSectionOptions(optionMultipleSelect);
            setOptions(optionMultipleSelect);
            return optionMultipleSelect;
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoadUser(false);
        }
    };

    const changeStartTime = async (value: any) => {
        const startTime = changeTime(value);
        const endTime = getValues('end_time');
        if (
            endTime &&
            !(endTime.hour() === 0 && endTime.minute() === 0) &&
            (startTime.hour() > endTime.hour() ||
                (endTime.hour() === startTime.hour() && endTime.minute() === startTime.minute()))
        ) {
            let endTimes;
            if (startTime.hour() === 23 && (startTime.minute() === 30 || startTime.minute() === 0)) {
                endTimes = startTime.startOf('day').subtract(1, 'day');
            } else if (startTime.hour() === 0 && startTime.minute() === 0) {
                setValue('end_time', endTime);
                clearErrors('end_time');
                return startTime;
            } else {
                endTimes = startTime.add(1, 'hour');
            }
            clearErrors('end_time');
            setValue('end_time', endTimes);
        } else if (startTime.hour() === 23 && startTime.minute() === 30) {
            const endTimes = startTime.startOf('day');
            clearErrors('end_time');
            setValue('end_time', endTimes);
        }
        return startTime;
    };

    const changeEndTime = async (value: any) => {
        const endTime = changeTime(value);
        const startTime = getValues('start_time');
        if (
            startTime &&
            (endTime.hour() < startTime.hour() ||
                (endTime.hour() === startTime.hour() && endTime.minute() === startTime.minute()))
        ) {
            let startTimes = startTime;
            if (endTime.hour() === 0 && (endTime.minute() === 30 || endTime.minute() === 0)) {
                if (endTime.minute() === 30) {
                    startTimes = endTime.startOf('day');
                }
            } else {
                startTimes = endTime.subtract(1, 'hour');
            }
            clearErrors('start_time');
            setValue('start_time', startTimes);
        } else if (endTime.hour() === 0 && endTime.minute() === 30) {
            const startTimes = endTime.startOf('day');
            clearErrors('start_time');
            setValue('start_time', startTimes);
        }
        return endTime;
    };

    const changeTime = (value: any) => {
        const roundedMinutes = value.minute() > 0 && value.minute() <= 30 ? 30 : 0;
        let changeTime = value.clone().minute(roundedMinutes);
        if (value.minute() > 30) {
            changeTime = changeTime.add(1, 'hour');
        }
        return changeTime.second(0);
    };

    const disabledTime = () => {
        const minutes: any = [];
        for (let i = 1; i <= 60; i++) {
            if (i !== 0 && i !== 30) {
                minutes.push(i);
            }
        }
        return minutes;
    };

    const handleSelectAll = (label: string, checked: boolean) => {
        const select_users = getValues('select_users');
        const current_select_users = getValues('user_ids') as string[];
        const options = select_users[label] as SelectedOption[];
        const ids = options.map((option) => option.value);
        const user_ids = checked
            ? _.uniq(_.concat(current_select_users, ids))
            : current_select_users.filter((id) => !ids.includes(id));
        setValue('user_ids', user_ids, { shouldValidate: true });
        select(user_ids);
    };

    const renderOptions = (options: Section[]) => {
        const user_ids = getValues('user_ids') as string[];

        return options.map((section, idx) => {
            const ids = section.options.map((option) => option.value);
            const isContain = _.difference(ids, user_ids).length === 0;

            return (
                <OptGroup
                    id={idx}
                    key={idx}
                    label={
                        <CustomLabel
                            optGroupLabel={section.label}
                            labelSelect={labelSelectAll}
                            onChange={handleSelectAll}
                            t={t}
                            defaultChecked={isContain}
                            isEmpty={!section.options.length}
                        />
                    }
                >
                    {section.options.map((item) => {
                        return (
                            <Option key={item.value} value={item.value}>
                                <TooltipComponent title={item.label}>
                                    <div
                                        className={
                                            'text-[15px] max-w-[130px] md:max-w-[300px] ellipsis whitespace-break-spaces option'
                                        }
                                    >
                                        {' '}
                                        {item.label}
                                    </div>
                                </TooltipComponent>
                            </Option>
                        );
                    })}
                </OptGroup>
            );
        });
    };

    const selectUserChange = (checked: CheckedState, field: Record<'id', string> & SelectedOption) => {
        let user_ids: string[] = getValues('user_ids') || [];
        user_ids = checked ? _.concat(user_ids, field.value) : _.filter(user_ids, (id) => id !== field.value);
        setValue('user_ids', user_ids, { shouldValidate: true });
        select(user_ids);
    };

    const select = (user_ids: string[]) => {
        const selected_users = getValues('select_users');
        const baseEntries = _.entries<SelectedOption[]>(selected_users);
        const entries = baseEntries.map((group) => group[1].map((item) => ({ ...item, section: group[0] })));

        baseEntries.forEach(([label, options]) => {
            const ids = options.map((option) => option.value);
            const isContainAll = _.difference(ids, user_ids).length === 0;
            setValue(`selectAll_${label}`, isContainAll);
        });

        const options = _.flatten(entries);

        options.forEach((option) => {
            option.checked = _.includes(user_ids, option.value);
        });

        const selected = _.groupBy(options, 'section');

        const newSectionOptions = _.entries(selected).map(([label, options]) => ({
            label,
            options,
        }));

        setSectionOptions((prev: Section[]) => {
            return prev.map((item) => {
                const options =
                    newSectionOptions.find((section) => section.label === item.label)?.options || item.options;

                return { ...item, options };
            });
        });
    };

    const handleSearchUser = _.debounce((name: string) => {
        if (_.isEmpty(name)) {
            setOptions(option.data);
        } else {
            const newSections = _.cloneDeep(option.data);
            const afterFilterSections = newSections.map((section) => ({
                ...section,
                options: section.options.filter(
                    (option) => option.label.toLowerCase().indexOf(name.toLowerCase()) >= 0
                ),
            }));
            setOptions(afterFilterSections.filter((section) => section.options.length > 0));
        }
    }, 500);

    const onDropdownVisibleChange = (visible: boolean) => {
        if (!visible) {
            setOptions(option.data);
        }
    };

    const onSelectChange = (value: string[]) => {
        select(value);
    };

    const labelSelectAll = t('meeting.form_meeting.select_all');

    return (
        <div
            className={cn('flex flex-col mt-4', {
                'gap-6': type !== 'detail',
            })}
            ref={container}
        >
            {type === 'detail' ? (
                <Item
                    label={t('meeting.form_meeting.name')}
                    className="sm:flex-nowrap whitespace-break-spaces"
                    labelClass="min-w-[200px] xl:min-w-[200px]"
                >
                    {renderText(getValues('name') || '')}
                </Item>
            ) : (
                <FormInputText
                    name="name"
                    labelClass="min-w-[200px]"
                    label={t('meeting.form_meeting.name')}
                    placeholder={t('meeting.form_meeting.placeholder_meeting_name')}
                    maxLength={255}
                />
            )}

            {type !== 'detail' ? (
                <FormInputDate
                    required={true}
                    name="meeting_date"
                    label={t('meeting.form_meeting.date')}
                    placeholder={t('meeting.form_meeting.placeholder_date')}
                    labelClass="min-w-[200px]"
                />
            ) : (
                <Item
                    label={t('meeting.form_meeting.date')}
                    className="sm:flex-nowrap whitespace-break-spaces mt-4"
                    labelClass="min-w-[200px] xl:min-w-[200px]"
                >
                    {renderText(getValues('meeting_date') || '')}
                </Item>
            )}
            <div className={'flex flex-wrap sm:flex-nowrap gap-4'}>
                <FormLabel className="text-[21px] min-w-[200px] max-h-[60px] " required={type !== 'detail'}>
                    {t('meeting.form_meeting.time')}
                </FormLabel>
                <div
                    className={cn(
                        'flex flex-row gap-4 sm:pl-2',
                        type === 'created' && 'flex-wrap min-[815px]:flex-nowrap'
                    )}
                >
                    {type !== 'detail' ? (
                        <FormInputTime
                            showNow={false}
                            required={true}
                            disabledTime={disabledTime}
                            onChangeTime={changeStartTime}
                            label={t('meeting.form_meeting.start')}
                            name="start_time"
                            inputClassName="sm:w-[150px]"
                        />
                    ) : (
                        <Item
                            label={t('meeting.form_meeting.start')}
                            className="sm:flex-nowrap whitespace-break-spaces mt-4"
                            labelClass="min-w-[50px] xl:min-w-[50px]"
                        >
                            {renderText(getValues('start_time') || '')}
                        </Item>
                    )}
                    <div className={cn('text-[40px] px-4')}>~</div>
                    {type !== 'detail' ? (
                        <FormInputTime
                            required={false}
                            showNow={false}
                            disabledTime={disabledTime}
                            onChangeTime={changeEndTime}
                            label={t('meeting.form_meeting.end')}
                            name="end_time"
                            inputClassName="sm:w-[150px]"
                        />
                    ) : (
                        <Item
                            label={t('meeting.form_meeting.end')}
                            className="sm:flex-nowrap whitespace-break-spaces mt-4"
                            labelClass="min-w-[50px] xl:min-w-[50px]"
                        >
                            {renderText(getValues('end_time') || '')}
                        </Item>
                    )}
                </div>
            </div>
            <FormInputTextarea
                required={false}
                disabled={type === 'detail'}
                labelClass="min-w-[200px]"
                name={'content'}
                label={t('meeting.form_meeting.meeting_content')}
                placeholder={
                    type !== 'detail' ? t('common.MSG_001', { field: t('meeting.form_meeting.meeting_content') }) : ''
                }
            />
            {type !== 'detail' && (
                <div ref={selectUserContainer}>
                    <FormMultipleSelect
                        isLoading={isLoadUser}
                        placeholder={t('common.MSG_002', { field: t('meeting.form_meeting.attendee_selection') })}
                        label={t('meeting.form_meeting.attendee_selection')}
                        name={'user_ids'}
                        className={'items-start'}
                        selectClass="select-selector"
                        onSearch={handleSearchUser}
                        filterOption={false}
                        onDropdownVisibleChange={onDropdownVisibleChange}
                        allowClear={false}
                        onChange={onSelectChange}
                        getPopupContainer={() => container.current}
                        popupClassName="select-selector-popup"
                    >
                        {renderOptions(options)}
                    </FormMultipleSelect>
                    <div className="space-y-2 flex flex-wrap sm:flex-nowrap sm:gap-6 items-start">
                        <div className="inline-block min-w-[200px]" />
                        <div
                            className={`flex animate-accordion-up flex-col gap-y-4 my-4 px-4 py-2 shadow-lg shadow-slate-300 rounded-md w-full`}
                        >
                            {isLoadUser ? (
                                <span className="p-4 flex justify-center">
                                    <SpinIcon className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-foreground" />
                                </span>
                            ) : (
                                <>
                                    {sectionOptions.map((section, index, arr) => {
                                        const isLast = index === arr.length - 1;
                                        return (
                                            <section
                                                className={cn('flex flex-col gap-3 pb-6', {
                                                    'border-b-2': !isLast,
                                                    'border-b-slate-400': !isLast,
                                                })}
                                                key={section.label}
                                            >
                                                <div className="flex items-center gap-x-6 pb-4">
                                                    <p className="inline-block text-[#ccc]">{section.label}</p>
                                                    {!!section.options.length && (
                                                        <div className="inline-block">
                                                            <FormInputCheckbox
                                                                classNameLabel="text-gray-600"
                                                                label={labelSelectAll}
                                                                name={`selectAll_${section.label}`}
                                                                onCheckboxChange={(checked) =>
                                                                    handleSelectAll(section.label, checked as boolean)
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                <FormInputCheckboxGroup
                                                    options={section.options}
                                                    onCheckboxChange={selectUserChange}
                                                    name={`select_users.${section.label}`}
                                                    classNameLabel="max-w-[145px] max-h-[30px] inline-block whitespace-break-spaces ellipsis"
                                                    containerClass="max-h-[210px] overflow-y-auto"
                                                />
                                            </section>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default observer(MeetingForm);
